html {
  height: 100%;
  width: 100%;
}

body, #root, .App {
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body{
  background-color: aliceblue;
}

.card {
  margin-top: 30px;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f5f5f5 !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.card-header {
  background-color: #282c34 !important;
  color: white;
}

.card-body.overflow-auto{
  max-height: 20rem;
}

.App {
  text-align: center;
  padding-bottom: 1rem;
}

.App-logo {
  /*height: 40vmin;*/
  height: 10vmin;
  pointer-events: none;
}

/* .home {
  margin: 12.1rem 0;
} */

/* .card {
  margin: 0 auto;
} */

.launch-buttons {
  padding: 5px;
}

.launch-buttons button {
  min-width: 5rem;
}

.App-link {
  color: #61dafb;
}

.login-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.container {
  margin-top: 1rem
}

.center {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}

.values-header{
  text-align: center;
  align-items: center;
  background-color: #282c34;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #fff;
}

.values-header h5 {
  margin-top: 1rem;
}

.show-line {
  text-align: center;
  align-items: center;
  background-color:#ffffff;
  border-top: 2px dashed #8c8b8b;
  margin-top: 2rem;
  margin-bottom: 2rem;
}


.input-group-prepend {
  cursor: pointer;
}

.buttons-wrapper {
  justify-content: center;
}

.configForm {
  background-color: #f5f5f5;
  padding: 1rem;
}

.testForm {
  background-color: #f5f5f5;
  padding: 1rem;
}

.tests {
  text-align: left;
}

.react-confirm-alert{
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #282c34;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.alert-delete > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

.button-add-test {
  float: right;
  padding: .5rem 0;
}

.logout {
  float: right;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  padding: 15px 0;
  background: #282c34;
  position: relative;
}

.logout-wraapper {
  top: 50%;
  position: absolute;
  color: #fff;
  transform: translateY(-50%);
  right: 30px;
}

i.logout {
  font-size: 28px;
}

.back-wraapper {
  position: absolute;
  color: #fff;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}

i.back {
  font-size: 28px;
}

.brandList{
  text-align: left;
}
.addBrand{
  float: right;
}

.footer-credit{
  font-size: small;
}

.unknown {
  background: #ecb1b1;
  vertical-align: middle;
  line-height: 90px; 
}

.unknown .col-md-3 {
  background: #bf4747;
  text-align: center;
}

.stopped {
  background: #9ad89a;
  vertical-align: middle;
  line-height: 90px; 
}

.stopped .col-md-3 {
  background: #509450;
  text-align: center;
}

.started {
  background: #9ad89a;
  vertical-align: middle;
  line-height: 90px; 
}

.started .col-md-3 {
  background: #509450;
  text-align: center;
}

.links-wrapper {
  padding: .75rem 1.25rem;
}

.links-wrapper .list {
  width: 80%;
  margin-right: 1rem;
}

.card.card-login {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
  margin: 1rem;
}