.list-environment > div {
    margin-bottom: 15px;
}

.list-environment .list-group, 
.list-environment .list-group-item {
    border-radius: .25rem !important;
}

.list-environment.list-group .list-group-item+.list-group-item {
    border-top-width: 1px;
}

.list-environment.list-group > .list-group-item {
    border-top-width: 1px;
    text-transform:capitalize;
    background-color: rgb(40, 44, 52);
    color: white;
    margin: 0.2rem;
}

.list-environment.list-group > .list-group-item.list-group-item-action:focus, 
.list-environment.list-group > .list-group-item.list-group-item-action:hover {
    z-index: 1;
    background-color: rgb(82, 87, 100);
    text-decoration: none;
}

.list-region.list-group > .list-group-item {
    background-color: #f5f5f5 !important;
    color: #495057;
    text-align: inherit;
}

.list-region.list-group > .list-group-item.list-group-item-action:focus, 
.list-region.list-group > .list-group-item.list-group-item-action:hover {
    z-index: 1;
    background-color: #e0dddd !important;
    /*border-color: #fff !important;
    border-width: 2px;*/
    box-sizing: border-box;
    box-shadow: inset 0 0 0 1px rgb(255, 254, 254);
    text-decoration: none;
}

a.list-group-item-action.createdByMe {
    font-weight: 600;
    border-width: 2px;
    border-color: #495057;;
}

a.list-group-item-action:hover {
    border-color: rgb(40, 44, 52) !important;
}

.Collapsible__trigger {
    cursor: pointer;
}

.Collapsible__trigger.is-open {
    padding-bottom: 10px;
}